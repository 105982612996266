<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="light-blue lighten-5"
  >
    <!-------------------------------------------STEPPER--------------------------------------->
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-stepper
            v-model="e1"
            elevation="0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                Eligibility
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                Policy Options
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 3"
                step="3"
              >
                Lifestyle
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="e1 > 4"
                step="4"
              >
                Medical
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="e1 >= 5"
                step="5"
              >
                Your Policy
              </v-stepper-step>
            </v-stepper-header>
            <!----------------------- STEP 2: ELIGIBILITY/INITIAL INFO------------------------------------>
            <v-stepper-items>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="1">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Eligibility
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <p>
                          What is your household income? <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                class="mb-1"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>Household income refer to the earned income of the primary wage earner(s) of the household, e.g. spouses or couple, received in the past 12 months.</span>
                          </v-tooltip>
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Household Income"
                          rules="required"
                        >
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="pa-0 ma-0"
                          >
                            <v-select
                              v-model="householdIncome"
                              outlined
                              color="primary"
                              :items="incomes"
                              label="Select One"
                              :error-messages="errors"
                              @change="incomeEligibility()"
                              @click="clickedFieldGlobal('householdIncome', 1, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </v-col>
                        </validation-provider>
                        <p>Do you work in any of the following occupations; mining or quarry worker, steeplejack, blaster or explosive handler, bridge construction, structural steel or iron worker, off shore duties in fishing or oil/gas industry, professional diver, foreign aid worker, foreign journalist, diplomat, logging worker, professional sports or military personnel?</p>
                        <validation-provider
                          name="Dangerous Occupation"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="dangerousOccupation"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @change="addKickout('Not eligible due to occupation.')"
                              @click="clickedFieldGlobal('dangerousOccuptation true', 2, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="dangerousOccuptation = 'false', clickedFieldGlobal('dangerousOccuptation false', 2, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>Are you a Canadian citizen or permanent resident of Canada?</p>
                        <validation-provider
                          name="Citizen"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="citizen"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="clickedFieldGlobal('citizen true', 3, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @change="addKickout('You must be a Canadian citizen or permanent resident of Canada to be eligible for this coverage.')"
                              @click="clickedFieldGlobal('citizen false', 3, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>
                          Do you intend to use this policy to replace, reduce, or cancel an individual life insurance policy you already have? <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                class="mb-1"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>For example, group life insurance obtained through your employer or creditor insurance obtained to protect a mortgage or another loan is not considered individual life insurance.</span>
                          </v-tooltip>
                        </p>
                        <validation-provider
                          name="Replacement"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="replacePolicy"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @change="addKickout('We will need to ask you some questions about your existing coverage to determine whether you are eligible for Parachute coverage. Please call our call centre at 1-833-756-0372.')"
                              @click="clickedFieldGlobal('replacePolicy true', 4, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="clickedFieldGlobal('replacePolicy false', 4, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>Within the last 12 months, have you used tobacco in any form (with the exception of one large cigar per month), nicotine products, nicotine substitutes, e-cigarettes, vaping or smoking cessation products, or consumed or smoked marijuana or hashish more than three times per week?</p>
                        <validation-provider
                          name="Tobacco"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="smoker"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="clickedFieldGlobal('smoker true', 5, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="clickedFieldGlobal('smoker false', 5, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('eligibility')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="2">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Policy Options
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="10"
                      >
                        <p>Enter your full name</p>
                        <div>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="4"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="First Name"
                                :rules="{ required: true, regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/, max: 20 }"
                              >
                                <v-text-field
                                  v-model="firstName"
                                  label="First Name"
                                  color="primary"
                                  outlined
                                  :error-messages="errors"
                                  @click="clickedFieldGlobal('firstName', 6, 'CA Term R&C Survey v1')"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="M.I."
                                rules="alpha"
                              >
                                <v-text-field
                                  v-model="middleInitial"
                                  v-mask="'A'"
                                  :error-messages="errors"
                                  label="M.I."
                                  color="primary"
                                  outlined
                                  @click="clickedFieldGlobal('middleInitial', 7, 'CA Term R&C Survey v1')"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Last Name"
                                :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                              >
                                <v-text-field
                                  v-model="lastName"
                                  label="Last Name"
                                  color="primary"
                                  outlined
                                  :error-messages="errors"
                                  @click="clickedFieldGlobal('lastName', 8, 'CA Term R&C Survey v1')"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-select
                                v-model="suffix"
                                :items="suffixes"
                                label="Suffix"
                                color="primary"
                                outlined
                                @click="clickedFieldGlobal('suffix', 9, 'CA Term R&C Survey v1')"
                              />
                            </v-col>
                          </v-row>
                        </div>
                        <p>Sex at Birth</p>
                        <validation-provider
                          name="Gender"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="gender"
                            mandatory
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="Male"
                              @click="clickedFieldGlobal('gender male', 10, 'CA Term R&C Survey v1')"
                            >
                              Male
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="Female"
                              @click="clickedFieldGlobal('gender female', 10, 'CA Term R&C Survey v1')"
                            >
                              Female
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>Please enter date of birth</p>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Day"
                              rules="required"
                            >
                              <v-select
                                v-model="bdays"
                                :items="daysavailable"
                                label="Day"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="clickedFieldGlobal('bdays', 11, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Month"
                              rules="required"
                            >
                              <v-select
                                v-model="bmonths"
                                :items="monthsavailable"
                                item-text="state"
                                item-value="abbr"
                                label="Month"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="clickedFieldGlobal('bmonths', 12, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Year"
                              rules="required"
                            >
                              <v-select
                                v-model="byears"
                                :items="yearsavailable"
                                label="Year"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @click="clickedFieldGlobal('byears', 13, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        Age : {{ calcage }}
                        <v-row>
                          <v-col>
                            <h3>Choose the term length <span class="text-h3 font-weight-bold ml-3 primary--text">{{ termLengths[termLength] }}</span></h3>
                            <v-row>
                              <v-col>
                                <v-slider
                                  v-model="termLength"
                                  min="0"
                                  max="2"
                                  step="1"
                                  ticks="always"
                                  :tick-labels="termLengths"
                                  tick-size="4"
                                  @change="clickedField(`termLength: ${termLengths[termLength]}`, 14)"
                                >
                                </v-slider>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-col
                              class="d-inline-flex align-center"
                            >
                              <h3>
                                Choose the face amount of the policy
                              </h3>
                              <div class="text-h3 font-weight-bold ml-3 primary--text">
                                {{ faceAmount }}
                              </div>
                            </v-col>
                          </v-col>
                        </v-row>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            v-model="faceAmount"
                            :error-messages="errors"
                            :items="faceAmounts"
                            label="Select One"
                            outlined
                            color="primary"
                            @change="chooseAmount, clickedField(`faceAmount: ${faceAmount}`, 15)"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('policyOptions')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!---------------------------------STEP 3 LIFESTYLE INFORMATION---------------------------------------->
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="3">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Lifestyle
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <v-divider
                            class="test text-center"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <!--------------------------------BMI CALCULATOR---------------------------------------->
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <p class="font-weight-bold">
                          BMI: {{ calcBMI }}
                        </p>
                        <p class="mt-2">
                          Choose Units of Measurement
                        </p>
                        <v-btn-toggle
                          v-model="units"
                          class="mb-4"
                          mandatory
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="metric"
                            @change="units === 'metric'"
                          >
                            Metric
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="standard"
                            @change="units === 'standard'"
                          >
                            Standard
                          </v-btn>
                        </v-btn-toggle>
                        <p>Enter Height</p>
                        <div v-if="units === 'metric'">
                          <v-col
                            cols="4"
                            class="pa-0 ma-0"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Height"
                              rules="required|numeric"
                            >
                              <v-text-field
                                v-model="centimeters"
                                label="Centimeters"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @blur="checkCentimeters()"
                                @click="clickedFieldGlobal('centimeters', 16, 'CA Term R&C Survey v1')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </div>
                        <div
                          v-if="units === 'standard'"
                        >
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pa-0 ma-0"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Height"
                              rules="required|numeric"
                            >
                              <v-text-field
                                v-model="inches"
                                label="Inches"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @blur="checkInches()"
                                @click="clickedFieldGlobal('inches', 16, 'CA Term R&C Survey v1')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </div>
                        <p>Enter Weight</p>
                        <div v-if="units === 'metric'">
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pa-0 ma-0"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Weight"
                              rules="required|numeric"
                            >
                              <v-text-field
                                v-model="kilograms"
                                label="Kilograms"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @blur="checkKilograms(); checkBMI()"
                                @click="clickedFieldGlobal('kilograms', 17, 'CA Term R&C Survey v1')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </div>
                        <div v-if="units === 'standard'">
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pa-0 ma-0"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Weight"
                              rules="required|numeric"
                            >
                              <v-text-field
                                v-model="pounds"
                                label="Pounds"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @blur="checkPounds(); checkBMI()"
                                @click="clickedFieldGlobal('pounds', 17, 'CA Term R&C Survey v1')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </div>
                        <!-------------------------------------SURVEY QUESTIONS---------------------------------------->
                        <p>Have you ever had a life, disability or health insurance policy declined, modified, rated (i.e. a premium surcharge) or rescinded by an insurance company?</p>
                        <validation-provider
                          name="Previously Declined"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="beenDeclined"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @change="addKickout('Ineligible for coverage due to a declined policy.')"
                              @click="clickedFieldGlobal('beenDeclined yes', 18, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="clickedFieldGlobal('beenDeclined no', 18, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>
                          In the past 10 years, have you: <br>
                          • been convicted of any criminal or drug-related offences or do you have any charges that are currently pending; or <br>
                          • been charged with a dangerous or impaired driving charge or have you had your driver’s license suspended or revoked?
                        </p>
                        <validation-provider
                          name="Previously Convicted"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="criminalHistory"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @change="addKickout('Ineligible for coverage due to criminal history.')"
                              @click="clickedFieldGlobal('criminalHistory yes', 19, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="clickedFieldGlobal('criminalHistory no', 19, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!----------------------------------DANGEROUS HOBBIES---------------------------------------->
                        <p>
                          In the past 2 years or in the next 2 years, did you or do you plan to fly an aircraft, motor race, sky diving, scuba diving (>100ft), mountain climb (>10,000ft), hang glide, heliski / backcountry ski, bungee jump or paraglide / kitesurf or participate in any other hazardous activities?
                        </p>
                        <validation-provider
                          name="Dangerous Hobbies"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="dangerousHobbies"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="dangerousHobbies = true, clickedFieldGlobal('dangerousHobbies yes', 20, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="dangerousHobbies = false, clickedFieldGlobal('dangerousHobbies no', 20, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="dangerousHobbies === 'true'">
                          <p class="font-weight-bold">
                            You answered yes. Please answer the following questions.
                          </p>
                          <p>What is the total number of times you participated in any of these activities in the <span class="font-weight-bold">past</span> 2 years?</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Number of times"
                            rules="required"
                          >
                            <v-select
                              v-model="participatedInPast2Years"
                              outlined
                              color="primary"
                              :items="[0, 1, 2, '3 or more']"
                              :disabled="disable"
                              :error-messages="errors"
                              @change="disable = true"
                              @click="clickedFieldGlobal('participatedInPast2Years', 21, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </validation-provider>
                          <div v-if="participatedInPast2Years !== ''">
                            <p>How many times do you plan to participate in any of these activities in the <span class="font-weight-bold">next</span> 2 years?</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Number of times"
                              rules="required"
                            >
                              <v-select
                                v-model="participateInNext2Years"
                                outlined
                                color="primary"
                                :items="[0, 1, 2, '3 or more']"
                                :error-messages="errors"
                                @change="hobbyKickout()"
                                @click="clickedFieldGlobal('participateInNext2Years', 22, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </div>
                        </div>
                        <!---------------------------------TRAVEL---------------------------------------->
                        <div>
                          <p>Within the past 12 months, have you traveled, lived, or worked anywhere <span class="font-weight-bold">other</span> than Canada, the U.S., U.K., European Union, Australia and New Zealand for more than 30 consecutive days, or do you intend to in the next 12 months?</p>
                          <validation-provider
                            name="Travel"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="traveled"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="traveled = 'true', clickedFieldGlobal('traveled true', 23, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="traveled = 'false', clickedFieldGlobal('traveled false', 23, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="traveled === 'true'">
                            <p>Please select which country</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Destination"
                              rules="required"
                            >
                              <v-select
                                v-model="destination"
                                outlined
                                color="primary"
                                :items="destinations"
                                :error-messages="errors"
                                @change="destinationKickout()"
                                @click="clickedFieldGlobal('destination', 24, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                            <div v-if="destination === 'Destination A'">
                              <p>How many weeks do you expect to spend in the selected country in the next 12 months?</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Weeks In Country"
                                rules="required"
                              >
                                <v-select
                                  v-model="weeksInCountry"
                                  outlined
                                  color="primary"
                                  :items="weeksArray"
                                  :error-messages="errors"
                                  @change="travelTimeKickout()"
                                  @click="clickedFieldGlobal('weeksInCountry', 25, 'CA Term R&C Survey v1')"
                                ></v-select>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <!-------------------------------------DRUG USE---------------------------------------->
                        <p>In the past 5 years, have you used drugs recreationally (including but not limited to marijuana, amphetamines/cocaine, sedatives/tranquilizers, narcotics/painkillers, opioids such as heroin and fentanyl)?</p>
                        <validation-provider
                          name="Drug Use"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="doneRecreationalDrugs"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="doneRecreationalDrugs === 'true', clickedFieldGlobal('doneRecreationalDrugs true', 26, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="doneRecreationalDrugs === 'false', clickedFieldGlobal('doneRecreationalDrugs false', 26, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="doneRecreationalDrugs === 'true'">
                          <p>Which of the following drugs have you used?</p>
                          <p>Marijuana</p>
                          <validation-provider
                            name="Marijuana"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="usedMarijuana"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="usedMarijuana === 'true', clickedFieldGlobal('usedMarijuana true', 27, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="usedMarijuana === 'false', clickedFieldGlobal('usedMarijuana false', 27, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="usedMarijuana === 'true'">
                            <p>Do you use marijuana with tobacco?</p>
                            <validation-provider
                              name="Tobacco"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="marijuanaWithTobacco"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="marijuanaWithTobacco = 'true'; smoker = 'true', clickedFieldGlobal('marijuanaWithTobacco true', 28, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="marijuanaWithTobacco = false, clickedFieldGlobal('marijuanaWithTobacco false', 28, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                            <p>How many times do you consume marijuana per week?</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Marijuana Frequency"
                              rules="required"
                            >
                              <v-select
                                v-model="marijuanaFrequency"
                                outlined
                                color="primary"
                                :items="marijuanaFrequencies"
                                :error-messages="errors"
                                @change="marijuanaKickout()"
                                @click="clickedFieldGlobal('marijuanaFrequency', 29, 'CA Term R&C Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </div>
                          <p>Any other drug (including but not limited to marijuana, amphetamines/cocaine, sedatives/tranquilizers, narcotics/painkillers, opioids such as heroin and fentanyl)</p>
                          <validation-provider
                            name="Other Drug"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="otherDrugUse"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="otherDrugUse = true; addKickout('Denied due to drug use.'), clickedFieldGlobal('otherDrugUse true', 30, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="otherDrugUse = false, clickedFieldGlobal('otherDrugUse false', 30, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                        </div>
                        <p>On average, how many alcoholic drinks do you consume per week?</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Alcohol Frequency"
                          rules="required"
                        >
                          <v-col
                            cols="12"
                            sm="6"
                            class="ma-0 pa-0"
                          >
                            <v-select
                              v-model="alcoholFrequency"
                              outlined
                              color="primary"
                              label="Select One"
                              :items="alcoholFrequencies"
                              :error-messages="errors"
                              @change="alcoholKickout()"
                              @click="clickedFieldGlobal('alcoholFrequency', 31, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </v-col>
                        </validation-provider>
                        <p>In the past 10 years, have you been advised to reduce or discontinue your consumption of alcohol or drugs, or have you received or sought advice or treatment (including medication) related to your consumption of alcohol or drugs or have you joined a support group for alcohol or drug abuse?</p>
                        <validation-provider
                          name="Alcohol or Drug Abuse"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="addiction"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="addiction = 'true'; addKickout('Denied due to alcohol or drug treatment.'), clickedFieldGlobal('addiction true', 32, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="addiction = 'false', clickedFieldGlobal('addiction false', 32, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('term life options')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!-------------------------------------------MEDICAL----------------------------------------------->
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="4">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Medical
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <v-divider
                            class="test text-center"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <p>Have you, or any close biological relative (i.e. parent, brother or sister) been diagnosed with Huntington’s disease, polycystic kidney disease (PKD), heart disease, or cancer before age 50?</p>
                        <validation-provider
                          name="Relative"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="beenDiagnosed"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="beenDiagnosed = 'true', clickedFieldGlobal('beenDiagnosed true', 33, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="beenDiagnosed = 'false', clickedFieldGlobal('beenDiagnosed false', 33, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="beenDiagnosed === 'true'">
                          <div v-if="gender === 'Male'">
                            <p>Is the ONLY reason you answered yes to this question because a close biological relative had breast or ovarian cancer?</p>
                            <validation-provider
                              name="Cancer"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="femaleRelativeHadCancer"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="femaleRelativeHadCancer = 'true', clickedFieldGlobal('femaleRelativeHadCancer true', 34, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="femaleRelativeHadCancer = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('femaleRelativeHadCancer false', 34, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="gender === 'Female'">
                            <p>Is the ONLY reason you answered yes to this question because a close biological relative had prostate cancer?</p>
                            <validation-provider
                              name="Cancer"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="maleRelativeHadCancer"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="maleRelativeHadCancer = 'true', clickedFieldGlobal('maleRelativeHadCancer true', 35, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="maleRelativeHadCancer = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('maleRelativeHadCancer false', 35, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <p class="font-weight-bold">
                          Have you ever been diagnosed with, received treatment for or been recommended to undergo therapy or take medication for any of the following disorders:
                        </p>
                        <p>Cancer (other than basal cell carcinoma), leukemia, lymphoma, malignant melanoma, a spinal cord or brain tumour, or any other malignant tumor</p>
                        <validation-provider
                          name="Cancer"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="cancerDiagnosis"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="cancerDiagnosis = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('cancerDiagnosis true', 36, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="cancerDiagnosis = 'false', clickedFieldGlobal('cancerDiagnosis false', 36, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!----------------------------------------------HEART DISEASE/ DIABETES----------------------------------------------->
                        <p>Diabetes, stroke, transient ischemic attack (TIA), heart disease or surgery, heart attack, peripheral artery disease</p>
                        <validation-provider
                          name="HeartDisease"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="heartDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="heartDisease = 'true', clickedFieldGlobal('heartDisease true', 37, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="heartDisease = 'false', clickedFieldGlobal('heartDisease false', 37, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="heartDisease === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="heartDiseaseTypes"
                              :items="heartDiseaseList"
                              :error-messages="errors"
                              label="Select all that apply"
                              outlined
                              color="primary"
                              multiple
                              @change="checkHeartKickouts()"
                              @click="clickedFieldGlobal('heartDiseaseTypes', 38, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </validation-provider>
                          <div v-if="heartDiseaseTypes.includes('Diabetes') && gender === 'Female'">
                            <p>Was your diabetes the result of pregnancy (gestational diabetes), and have you since made a full recovery with no follow-up treatment?</p>
                            <validation-provider
                              name="Diabetes"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="diabetesFollowup"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="diabetesFollowup = 'true', clickedFieldGlobal('diabetesFollowup true', 39, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="diabetesFollowup = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('diabetesFollowup false', 39, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="heartDiseaseTypes.includes('Heart disease')">
                            <p>Is the condition a heart murmur?</p>
                            <validation-provider
                              name="HeartMurmur"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="heartDiseaseFollowup1"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="heartDiseaseFollowup1 = 'true', clickedFieldGlobal('heartDiseaseFollowup1 true', 40, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="heartDiseaseFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('heartDiseaseFollowup1 false', 40, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                            <div v-if="heartDiseaseFollowup1 === 'true'">
                              <p>Have you had surgery or been told you need surgery in the future for your heart murmur?</p>
                              <validation-provider
                                name="HeartMurmur"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="heartDiseaseFollowup2"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="heartDiseaseFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('heartDiseaseFollowup2 true', 41, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="heartDiseaseFollowup2 = 'false', clickedFieldGlobal('heartDiseaseFollowup2 false', 41, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="heartDiseaseFollowup2 === 'false'">
                              <p>In the last 12 months, have you been hospitalized or treated in the emergency room related to the heart murmur or have you missed work in the last 12 months due to the heart murmur?</p>
                              <validation-provider
                                name="HeartMurmur"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="heartDiseaseFollowup3"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="heartDiseaseFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('heartDiseaseFollowup3 true', 42, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="heartDiseaseFollowup3 = 'false', clickedFieldGlobal('heartDiseaseFollowup3 false', 42, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="heartDiseaseFollowup3 === 'false'">
                              <p>Do you take more than 1 prescribed medication daily for your heart murmur?</p>
                              <validation-provider
                                name="HeartMurmur"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="heartDiseaseFollowup4"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="heartDiseaseFollowup4 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('heartDiseaseFollowup4 true', 43, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="heartDiseaseFollowup4 = 'false', clickedFieldGlobal('heartDiseaseFollowup4 false', 43, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <!--------------------------------------------Cystic Fibrosis-------------------------------------------->
                        <p>Cystic Fibrosis or any respiratory condition which requires treatment with oxygen (excluding sleep apnea)</p>
                        <validation-provider
                          name="Cystic Fibrosis"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="cysticFibrosis"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="cysticFibrosis = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('cysticFibrosis true', 44, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="cysticFibrosis = 'false', clickedFieldGlobal('cysticFibrosis false', 44, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------Bone Marrow-------------------------------------------->
                        <p>Bone marrow transplant or any organ transplant (other than corneal transplant), or advised that one was required?</p>
                        <validation-provider
                          name="Bone Marrow"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="boneMarrow"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="boneMarrow = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('boneMarrow true', 45, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="boneMarrow = 'false', clickedFieldGlobal('boneMarrow false', 45, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------HIV/Aids-------------------------------------------->
                        <p>HIV/Aids or AIDS related complex</p>
                        <validation-provider
                          name="HIV/AIDS"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="hivAids"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="hivAids = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('hivAids true', 46, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="hivAids = 'false', clickedFieldGlobal('hivAids false', 46, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------Huntington’s-------------------------------------------->
                        <p>Huntington’s disease, muscular dystrophy, motor neuron disease, multiple sclerosis, or ALS or Lou Gehrig’s disease</p>
                        <validation-provider
                          name="Huntington’s"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="huntingtons"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="huntingtons = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('huntingtons true', 47, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="huntingtons = 'false', clickedFieldGlobal('huntingtons false', 47, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------COGNITIVE DISABILITY-------------------------------------------->
                        <p>Cognitive impairment, dementia, Alzheimer’s disease, Parkinson’s disease, paralysis, cerebral palsy, or any other neurological or brain disorder</p>
                        <validation-provider
                          name="Cognitive Disability"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="cognitiveDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="cognitiveDisease = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('cognitiveDisease true', 48, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="cognitiveDisease = 'false', clickedFieldGlobal('cognitiveDisease false', 48, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------Schizophrenia-------------------------------------------->
                        <p>Schizophrenia, psychosis, bipolar disorder, eating disorders, suicidal attempt</p>
                        <validation-provider
                          name="Schizophrenia"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="schizophrenia"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="schizophrenia = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('schizophrenia true', 49, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="schizophrenia = 'false', clickedFieldGlobal('schizophrenia false', 49, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------5 YEAR HISTORY-------------------------------------------->
                        <p class="font-weight-bold">
                          In the past 5 years, have you been diagnosed with, received treatment for or been recommended to undergo therapy or take medication for any of the following disorders:
                        </p>
                        <!--------------------------------------------BLOOD PRESSURE-------------------------------------------->
                        <p>High blood pressure, high cholesterol, blood clot or deep vein thrombosis or any other blood vessel or circulatory disease or disorder (ignore varicose veins)</p>
                        <validation-provider
                          name="Blood Pressure"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="bloodPressure"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="bloodPressure = 'true', clickedFieldGlobal('bloodPressure true', 50, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="bloodPressure = 'false', clickedFieldGlobal('bloodPressure false', 50, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="bloodPressure === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="bloodPressureTypes"
                              outlined
                              color="primary"
                              label="Please select all that apply"
                              :error-messages="errors"
                              :items="bloodPressureList"
                              multiple
                              @change="checkBloodPressureKickout()"
                              @click="clickedFieldGlobal('bloodPressureTypes', 51, 'CA Term R&C Survey v1')"
                            />
                          </validation-provider>
                          <div v-if="bloodPressureTypes.includes('High blood pressure')">
                            <p>Have you been evaluated by your doctor in the last six months for your high blood pressure and been told by your doctor that your most recent blood pressure reading was normal?</p>
                            <validation-provider
                              name="High blood pressure"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="bloodPressureFollowup"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="bloodPressureFollowup = 'true', clickedFieldGlobal('bloodPressureFollowup true', 52, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="bloodPressureFollowup = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('bloodPressureFollowup false', 52, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="bloodPressureTypes.includes('High cholesterol')">
                            <p>Have you been prescribed medication for high cholesterol?</p>
                            <validation-provider
                              name="High cholesterol"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="cholesterolFollowup1"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="cholesterolFollowup1 = 'true', clickedFieldGlobal('cholesterolFollowup1 true', 53, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="cholesterolFollowup1 = 'false', clickedFieldGlobal('cholesterolFollowup1 false', 53, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                            <div v-if="cholesterolFollowup1 === 'false'">
                              <p>Have you been told by a medical profession that your most recent cholesterol reading is well controlled without medication?</p>
                              <validation-provider
                                name="High cholesterol"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="cholesterolFollowup2"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="cholesterolFollowup2 = 'true', clickedFieldGlobal('cholesterolFollowup2 true', 54, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="cholesterolFollowup2 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('cholesterolFollowup2 false', 54, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <!--------------------------------------------CONVULSIONS-------------------------------------------->
                        <p>Convulsions, seizures or epilepsy</p>
                        <validation-provider
                          name="Convulsions"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="convulsions"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="convulsions = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('convulsions true', 55, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="convulsions = 'false', clickedFieldGlobal('convulsions false', 55, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!---------------------------------------------AUTOIMMUNE DISEASE---------------------------------------------->
                        <p>Rheumatoid or psoriatic arthritis, systemic lupus or any other immune system disorders</p>
                        <validation-provider
                          name="Autoimmune Disease"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="autoimmuneDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="autoimmuneDisease = 'true', clickedFieldGlobal('autoimmuneDisease true', 56, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="autoimmuneDisease = 'false', clickedFieldGlobal('autoimmuneDisease false', 56, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="autoimmuneDisease === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="autoimmuneDiseaseTypes"
                              :items="autoimmuneDiseaseList"
                              :error-messages="errors"
                              label="Select all that apply"
                              outlined
                              color="primary"
                              multiple
                              @change="checkAutoimmuneKickouts()"
                              @click="clickedFieldGlobal('autoimmuneDiseaseTypes', 57, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </validation-provider>
                        </div>
                        <div v-if="autoimmuneDiseaseTypes.includes('Rheumatoid or psoriatic arthritis')">
                          <p>Is your rheumatoid or psoriatic arthritis currently in remission (no symptoms and no medication) for more than 6 months?</p>
                          <validation-provider
                            name="Rheumatoid or psoriatic arthritis"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="arthritisFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="arthritisFollowup1 = 'true', clickedFieldGlobal('arthritisFollowup1 true', 58, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="arthritisFollowup1 = 'false', clickedFieldGlobal('arthritisFollowup1 false', 58, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="arthritisFollowup1 === 'false'">
                            <p>Are you fully active with no physical impairment and no use of aids?</p>
                            <validation-provider
                              name="Rheumatoid or psoriatic arthritis"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="arthritisFollowup2"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="arthritisFollowup2 = 'true', clickedFieldGlobal('arthritisFollowup2 true', 59, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="arthritisFollowup2 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('arthritisFollowup2 false', 59, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="arthritisFollowup2 === 'true'">
                            <p>Other than occasional to intermittent use of non-steroidal anti-inflammatory drugs (such as aspirin or ibuprofen), do you use any other prescribed medication for this condition, such as steroids or methotrexate?</p>
                            <validation-provider
                              name="Rheumatoid or psoriatic arthritis"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="arthritisFollowup3"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="arthritisFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('arthritisFollowup3 true', 60, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="arthritisFollowup3 = 'false', clickedFieldGlobal('arthritisFollowup3 false', 60, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <!---------------------------------------------------LIVER DISEASE------------------------------------------>
                        <p>Hepatitis (other than Hepatitis A), cirrhosis or any other liver disorder or disease</p>
                        <validation-provider
                          name="Liver Disease"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="liverDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="liverDisease = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('liverDisease true', 61, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="liverDisease = 'false', clickedFieldGlobal('liverDisease false', 61, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!------------------------------------------STOMACH---------------------------------------------------->
                        <p>Disorder or disease of the pancreas, stomach, esophagus or bowel including Crohn’s disease or ulcerative colitis</p>
                        <validation-provider
                          name="Stomach"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="stomachDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="stomachDisease = 'true', clickedFieldGlobal('stomachDisease true', 62, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="stomachDisease = 'false', clickedFieldGlobal('stomachDisease false', 62, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="stomachDisease === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="stomachDiseaseTypes"
                              :items="stomachDiseaseList"
                              :error-messages="errors"
                              label="Please select all that apply"
                              outlined
                              multiple
                              color="primary"
                              @change="checkStomachKickout()"
                              @click="clickedFieldGlobal('stomachDiseaseTypes', 63, 'CA Term R&C Survey v1')"
                            />
                          </validation-provider>
                          <div v-if="stomachDiseaseTypes.includes('Chron’s disease')">
                            <p>Were you diagnosed more than 12 months ago?</p>
                            <validation-provider
                              name="Chron’s disease"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="chronsFollowup1"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="chronsFollowup1 = 'true', clickedFieldGlobal('chronsFollowup1 true', 64, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="chronsFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('chronsFollowup1 false', 64, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                            <div v-if="chronsFollowup1 === 'true'">
                              <p>Have you ever had surgery for your crohn’s disease?</p>
                              <validation-provider
                                name="Chron’s disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup2"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="chronsFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('chronsFollowup2 true', 65, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="chronsFollowup2 = 'false', clickedFieldGlobal('chronsFollowup2 false', 65, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="chronsFollowup2 === 'false'">
                              <p>Have you been hospitalized for your crohn’s disease in the last three years?</p>
                              <validation-provider
                                name="Chron’s disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup3"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="chronsFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('chronsFollowup3 true', 66, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="chronsFollowup3 = 'false', clickedFieldGlobal('chronsFollowup3 false', 66, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="chronsFollowup3 === 'false'">
                              <p>Have you experienced any abdominal cramping, tenderness, or urgency due to your crohn’s disease in the last 90 days?</p>
                              <validation-provider
                                name="Chron’s disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup4"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="chronsFollowup4 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('chronsFollowup4 true', 67, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="chronsFollowup4 = 'false', clickedFieldGlobal('chronsFollowup4 false', 67, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="chronsFollowup4 === 'false'">
                              <p>Have you ever needed to take steroids (either orally or by enema) within the last three years to treat your crohn’s disease?</p>
                              <validation-provider
                                name="Chron’s disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup5"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="chronsFollowup5 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('chronsFollowup5 true', 68, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="chronsFollowup5 = 'false', clickedFieldGlobal('chronsFollowup5 false', 68, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                          </div>
                          <!-------------------------------------------ULCERATIVE COLITIS------------------------------------------>
                          <div v-if="stomachDiseaseTypes.includes('Ulcerative colitis')">
                            <p>Were you diagnosed more than 12 months ago?</p>
                            <validation-provider
                              name="Ulcerative colitis"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="colitisFollowup1"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="colitisFollowup1 = 'true', clickedFieldGlobal('colitisFollowup1 true', 69, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="colitisFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('colitisFollowup1 false', 69, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                            <div v-if="colitisFollowup1 === 'true'">
                              <p>Have you ever had surgery for your ulcerative colitis?</p>
                              <validation-provider
                                name="Ulcerative colitis"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup2"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="colitisFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('colitisFollowup2 true', 70, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="colitisFollowup2 = 'false', clickedFieldGlobal('colitisFollowup2 false', 70, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="colitisFollowup2 === 'false'">
                              <p>Have you experienced any abdominal cramping, tenderness, or urgency due to your ulcerative colitis in the last 90 days?</p>
                              <validation-provider
                                name="Ulcerative colitis"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup3"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="colitisFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('colitisFollowup3 true', 71, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="chronsFollowup3 = 'false', clickedFieldGlobal('colitisFollowup3 false', 71, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="chronsFollowup3 === 'false'">
                              <p>Have you been hospitalized for your ulcerative colitis in the last three years?</p>
                              <validation-provider
                                name="Ulcerative colitis"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="chronsFollowup4"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="colitisFollowup4 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('colitisFollowup4 true', 72, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="colitisFollowup4 = 'false', clickedFieldGlobal('colitisFollowup4 false', 72, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                            <div v-if="colitisFollowup4 === 'false'">
                              <p>Have you ever needed to take steroids (either orally or by enema) within the last three years to treat your ulcerative colitis?</p>
                              <validation-provider
                                name="Ulcerative colitis"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="colitisFollowup5"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="colitisFollowup5 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('colitisFollowup5 true', 73, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="colitisFollowup5 = 'false', clickedFieldGlobal('colitisFollowup5 false', 73, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <!---------------------------------------------------KIDNEY DISEASE------------------------------------------>
                        <p>Chronic kidney disease or polycystic kidney disease (PKD), glomerulonephritis, kidney dialysis, or any disorder or disease affecting the bladder or prostate.</p>
                        <validation-provider
                          name="Kidney Disease"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="kidneyDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="kidneyDisease = 'true', clickedFieldGlobal('kidneyDisease true', 74, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="kidneyDisease = 'false', clickedFieldGlobal('kidneyDisease false', 74, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="kidneyDisease === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="kidneyDiseaseTypes"
                              :items="kidneyDiseaseList"
                              :error-messages="errors"
                              label="Please select all that apply"
                              outlined
                              multiple
                              color="primary"
                              @change="checkKidneyKickout()"
                              @click="clickedFieldGlobal('kidneyDiseaseTypes', 75, 'CA Term R&C Survey v1')"
                            />
                            <div v-if="kidneyDiseaseTypes.includes('Any disorder or disease affecting the bladder')">
                              <p>Was your condition diagnosed as a urinary tract infection?</p>
                              <validation-provider
                                name="Kidney Disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="bladderFollowup1"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="bladderFollowup1 = 'true', clickedFieldGlobal('bladderFollowup1 true', 76, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="bladderFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('bladderFollowup1 false', 76, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                              <div v-if="bladderFollowup1 === 'true'">
                                <p>Was surgery required?</p>
                                <validation-provider
                                  name="Kidney Disease"
                                  rules="required"
                                >
                                  <v-btn-toggle
                                    v-model="bladderFollowup2"
                                    class="mb-4"
                                  >
                                    <v-btn
                                      color="blanketbutton"
                                      text
                                      outlined
                                      value="true"
                                      @click="bladderFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('bladderFollowup2 true', 77, 'CA Term R&C Survey v1')"
                                    >
                                      Yes
                                    </v-btn>
                                    <v-btn
                                      color="blanketbutton"
                                      text
                                      outlined
                                      value="false"
                                      @click="bladderFollowup2 = 'false', clickedFieldGlobal('bladderFollowup2 false', 77, 'CA Term R&C Survey v1')"
                                    >
                                      No
                                    </v-btn>
                                  </v-btn-toggle>
                                </validation-provider>
                                <div v-if="bladderFollowup2 === 'false'">
                                  <p>Has the infection fully resolved with antibiotics?</p>
                                  <validation-provider
                                    name="Kidney Disease"
                                    rules="required"
                                  >
                                    <v-btn-toggle
                                      v-model="bladderFollowup3"
                                      class="mb-4"
                                    >
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="true"
                                        @click="bladderFollowup3 = 'true', clickedFieldGlobal('bladderFollowup3 true', 78, 'CA Term R&C Survey v1')"
                                      >
                                        Yes
                                      </v-btn>
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="false"
                                        @click="bladderFollowup3 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('bladderFollowup3 false', 78, 'CA Term R&C Survey v1')"
                                      >
                                        No
                                      </v-btn>
                                    </v-btn-toggle>
                                  </validation-provider>
                                </div>
                              </div>
                            </div>
                            <div v-if="kidneyDiseaseTypes.includes('Any disorder or disease affecting the prostate')">
                              <p>Was your condition described as an infection?</p>
                              <validation-provider
                                name="Kidney Disease"
                                rules="required"
                              >
                                <v-btn-toggle
                                  v-model="prostateFollowup1"
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="true"
                                    @click="prostateFollowup1 = 'true', clickedFieldGlobal('prostateFollowup1 true', 79, 'CA Term R&C Survey v1')"
                                  >
                                    Yes
                                  </v-btn>
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="false"
                                    @click="prostateFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('prostateFollowup1 false', 79, 'CA Term R&C Survey v1')"
                                  >
                                    No
                                  </v-btn>
                                </v-btn-toggle>
                              </validation-provider>
                              <div v-if="prostateFollowup1 === 'true'">
                                <p>Do you currently have any symptoms, or are you receiving treatment, or are you pending a consultation or surgery?</p>
                                <validation-provider
                                  name="Kidney Disease"
                                  rules="required"
                                >
                                  <v-btn-toggle
                                    v-model="prostateFollowup2"
                                    class="mb-4"
                                  >
                                    <v-btn
                                      color="blanketbutton"
                                      text
                                      outlined
                                      value="true"
                                      @click="prostateFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('prostateFollowup2 true', 80, 'CA Term R&C Survey v1')"
                                    >
                                      Yes
                                    </v-btn>
                                    <v-btn
                                      color="blanketbutton"
                                      text
                                      outlined
                                      value="false"
                                      @click="prostateFollowup2 = 'false', clickedFieldGlobal('prostateFollowup2 false', 80, 'CA Term R&C Survey v1')"
                                    >
                                      No
                                    </v-btn>
                                  </v-btn-toggle>
                                </validation-provider>
                                <div v-if="prostateFollowup2 === 'false'">
                                  <p>Has your condition fully resolved?</p>
                                  <validation-provider
                                    name="Kidney Disease"
                                    rules="required"
                                  >
                                    <v-btn-toggle
                                      v-model="prostateFollowup3"
                                      class="mb-4"
                                    >
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="true"
                                        @click="prostateFollowup3 = 'true', clickedFieldGlobal('prostateFollowup3 true', 81, 'CA Term R&C Survey v1')"
                                      >
                                        Yes
                                      </v-btn>
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="false"
                                        @click="prostateFollowup3 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('prostateFollowup3 false', 81, 'CA Term R&C Survey v1')"
                                      >
                                        No
                                      </v-btn>
                                    </v-btn-toggle>
                                  </validation-provider>
                                </div>
                              </div>
                            </div>
                          </validation-provider>
                        </div>
                        <!------------------------------------------LUNG DISEASE---------------------------------------------------->
                        <p>Chronic obstructive pulmonary disease (COPD), emphysema, asthma, sleep apnea, or any other condition related to the respiratory system</p>
                        <validation-provider
                          name="Lung Disease"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="lungDisease"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="lungDisease = 'true', clickedFieldGlobal('lungDisease true', 82, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="lungDisease = 'false', clickedFieldGlobal('lungDisease false', 82, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="lungDisease === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="lungDiseaseTypes"
                              :items="lungDiseaseList"
                              :error-messages="errors"
                              label="Select all that apply"
                              outlined
                              color="primary"
                              multiple
                              @change="checkLungKickouts()"
                              @click="clickedFieldGlobal('lungDiseaseTypes', 83, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </validation-provider>
                        </div>
                        <!-------------------------------------------------ASTHMA---------------------------------------------------->
                        <div v-if="lungDiseaseTypes.includes('Asthma')">
                          <p>Have you ever been hospitalized for your asthma or in the last 2 years have you required oral steroids?</p>
                          <validation-provider
                            name="Lung Disease"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="ashtmaFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="ashtmaFollowup1 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('ashtmaFollowup1 true', 84, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="ashtmaFollowup1 = 'false', clickedFieldGlobal('ashtmaFollowup1 false', 84, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="ashtmaFollowup1 === 'false'">
                            <p>In the last two years, have you missed time from work for this condition (if employed)?</p>
                            <validation-provider
                              name="Lung Disease"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="ashtmaFollowup2"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="ashtmaFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('ashtmaFollowup2 true', 85, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="ashtmaFollowup2 = 'false', clickedFieldGlobal('ashtmaFollowup2 false', 85, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="ashtmaFollowup2 === 'false'">
                            <p>Do you experience symptoms of asthma more than 2 times per week?</p>
                            <validation-provider
                              name="Lung Disease"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="ashtmaFollowup3"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="ashtmaFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('ashtmaFollowup3 true', 86, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="clickedFieldGlobal('ashtmaFollowup3 false', 86), ashtmaFollowup3 = 'false'; if(smoker === 'true') { addKickout('Denied due to asthma and smoking.', 'CA Term R&C Survey v1') }"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <!---------------------------------------------------SLEEP APNEA--------------------------------------------------->
                        <div v-if="lungDiseaseTypes.includes('Sleep apnea') && bmi <= 32">
                          <p>Does your doctor describe your sleep apnea as mild (i.e. you are not prescribed use of a CPAP machine) and do you drink fewer than 2 alcohol drinks per day?</p>
                          <validation-provider
                            name="Lung Disease"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="sleepApneaFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="sleepApneaFollowup1 = 'true', clickedFieldGlobal('sleepApneaFollowup1 true', 87, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="sleepApneaFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('sleepApneaFollowup1 false', 87, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                        </div>
                        <!-------------------------------------------MENTAL HEALTH----------------------------------------------->
                        <p>Depression, anxiety or post-traumatic stress disorder</p>
                        <validation-provider
                          name="Mental Health"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="mentalHealth"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="mentalHealth = 'true', clickedFieldGlobal('mentalHealth true', 88, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="mentalHealth = 'false', clickedFieldGlobal('mentalHealth false', 88, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <div v-if="mentalHealth === 'true'">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Selection"
                            rules="required"
                          >
                            <v-select
                              v-model="mentalHealthTypes"
                              :items="mentalHealthList"
                              :error-messages="errors"
                              label="Select all that apply"
                              outlined
                              color="primary"
                              multiple
                              @click="clickedFieldGlobal('mentalHealthTypes', 89, 'CA Term R&C Survey v1')"
                            ></v-select>
                          </validation-provider>
                        </div>
                        <!-------------------------------------------DEPRESSION----------------------------------------------->
                        <div v-if="mentalHealthTypes.includes('Depression')">
                          <p>Was your depression diagnosed more than 12 months ago?</p>
                          <validation-provider
                            name="Mental Health"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="depressionFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="depressionFollowup1 = 'true', clickedFieldGlobal('depressionFollowup1 true', 90, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="depressionFollowup1 = 'false'; addKickout('Denied due to medical history.'), clickedFieldGlobal('depressionFollowup1 false', 90, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="depressionFollowup1 === 'true'">
                            <p>In the last 12 months, have you had more than 1 depressive episode, or missed time from work due to your depression?</p>
                            <validation-provider
                              name="Mental Health"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="depressionFollowup2"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="depressionFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('depressionFollowup2 true', 91, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="depressionFollowup2 = 'false', clickedFieldGlobal('depressionFollowup2 false', 91, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                          <div v-if="depressionFollowup2 === 'false'">
                            <p>Have you ever been hospitalized, had suicidal thoughts, attempted suicide, or overdosed on drugs or alcohol?</p>
                            <validation-provider
                              name="Depression"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="depressionFollowup3"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="depressionFollowup3 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('depressionFollowup3 true', 92, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="depressionFollowup3 = 'false', clickedFieldGlobal('depressionFollowup3 false', 92, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <!--------------------------------------------ANXIETY---------------------------------------------------->
                        <div v-if="mentalHealthTypes.includes('Anxiety')">
                          <p>Have you missed work in the last 12 months due to your anxiety?</p>
                          <validation-provider
                            name="Mental Health"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="anxietyFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="anxietyFollowup1 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('anxietyFollowup1 true', 93, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="anxietyFollowup1 = 'false', clickedFieldGlobal('anxietyFollowup1 false', 93, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="anxietyFollowup1 === 'false'">
                            <p>Have you ever been hospitalized, had suicidal thoughts, attempted suicide, or overdosed on drugs or alcohol?</p>
                            <validation-provider
                              name="Anxiety"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="anxietyFollowup2"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="anxietyFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('anxietyFollowup2 true', 94, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="anxietyFollowup2 = 'false', clickedFieldGlobal('anxietyFollowup2 false', 94, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <!-------------------------------------------PTSD----------------------------------------------->
                        <div v-if="mentalHealthTypes.includes('Post-traumatic stress disorder')">
                          <p>Have you missed work in the last 12 months due to your PTSD?</p>
                          <validation-provider
                            name="Mental Health"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="ptsdFollowup1"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="ptsdFollowup1 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('ptsdFollowup1 true', 95, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="ptsdFollowup1 = 'false', clickedFieldGlobal('ptsdFollowup1 false', 95, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                          <div v-if="ptsdFollowup1 === 'false'">
                            <p>Have you ever been hospitalized, had suicidal thoughts, attempted suicide, or overdosed on drugs or alcohol?</p>
                            <validation-provider
                              name="Mental Health"
                              rules="required"
                            >
                              <v-btn-toggle
                                v-model="ptsdFollowup2"
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                  @click="ptsdFollowup2 = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('ptsdFollowup2 true', 96, 'CA Term R&C Survey v1')"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                  @click="ptsdFollowup2 = 'false', clickedFieldGlobal('ptsdFollowup2 false', 96, 'CA Term R&C Survey v1')"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </validation-provider>
                          </div>
                        </div>
                        <!--------------------------------------------ADDITIONAL ILLNESS---------------------------------------------------->
                        <p>Other than for conditions already disclosed, in the past 12 months, have you had any medical condition, illness or injury that you’ve received treatment for over a continuous period of 4 weeks or more? (ignore oral contraception pill, pregnancies, minor accidents and injuries, for example, pulled or strained muscle, torn ligament or tendon, sprained joint)</p>
                        <validation-provider
                          name="Additional Illness"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="additionalIllness"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="additionalIllness = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('additionalIllness true', 97, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="additionalIllness = 'false', clickedFieldGlobal('additionalIllness false', 97, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------BREAST/PROSTRATE---------------------------------------------------->
                        <div v-if="calcage >= 50">
                          <p>In the past 2 years, if applicable to you, have you had an abnormal mammogram, breast imaging test, colonoscopy, or abnormal PSA test?</p>
                          <validation-provider
                            name="Breast/Prostrate"
                            rules="required"
                          >
                            <v-btn-toggle
                              v-model="mampros"
                              class="mb-4"
                            >
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="true"
                                @click="mampros = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('mampros true', 98, 'CA Term R&C Survey v1')"
                              >
                                Yes
                              </v-btn>
                              <v-btn
                                color="blanketbutton"
                                text
                                outlined
                                value="false"
                                @click="mampros = 'false', clickedFieldGlobal('mampros false', 98, 'CA Term R&C Survey v1')"
                              >
                                No
                              </v-btn>
                            </v-btn-toggle>
                          </validation-provider>
                        </div>
                        <!--------------------------------------------AWAITING RESULTS---------------------------------------------------->
                        <p>Other than for conditions already disclosed, has a healthcare provider recommended any medical investigation, follow-up, tests, surgery or referrals that have not yet been completed or are you currently awaiting results? (Do not tell us about genetic testing or genetic test results and ignore oral contraception pill, pregnancies, minor accidents and injuries, for example, pulled or strained muscle, torn ligament or tendon, sprained joints)</p>
                        <validation-provider
                          name="Awaiting Results"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="awaitingResults"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="awaitingResults = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('awaitingResults true', 99, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="awaitingResults = 'false', clickedFieldGlobal('awaitingResults false', 99, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------RECENT SYMPTOMS---------------------------------------------------->
                        <p>During the last 3 months, have you had any signs or symptoms listed below for which you have not yet consulted a healthcare provider or received treatment?<br>Chest pain, palpitations or irregular heartbeat <br> Unexplained bleeding, weight loss, lump or growth <br> Numbness or persistent tingling <br> Memory loss <br> A new cough lasting more than 3 weeks <br> Balance, movement or mobility problems <br> Any other sign or symptom that you may see a healthcare provider about for the first time</p>
                        <validation-provider
                          name="Recent Symptoms"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="recentSymptoms"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="recentSymptoms = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('recentSymptoms true', 100, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="recentSymptoms = 'false', clickedFieldGlobal('recentSymptoms false', 100, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <!--------------------------------------------COVID-19---------------------------------------------------->
                        <p>In the last 4 weeks, have you or anyone residing at the same address as you been diagnosed with Covid 19, been advised to self-isolate or had a COVID 19 test for which the results are not yet available?</p>
                        <validation-provider
                          name="COVID-19"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="covid"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="covid = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('covid true', 101, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="covid = 'false', clickedFieldGlobal('covid false', 101, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p>Have you travelled outside of Canada in the past 4 weeks, or will you travel outside of Canada in the next 4 weeks, in either case while a Government of Canada non-essential travel advisor was or is in effect for the region you were travelling to or from?</p>
                        <validation-provider
                          name="Travel Outside Canada"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="recentTravel"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                              @click="recentTravel = 'true'; addKickout('Denied due to medical history.'), clickedFieldGlobal('recentTravel true', 102, 'CA Term R&C Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                              @click="recentTravel = 'false', clickedFieldGlobal('recentTravel false', 102, 'CA Term R&C Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('policyOptions')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!----- their policy issued ---->
              <v-stepper-content step="5">
                <v-card
                  v-if="kickouts.length === 0"
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Policy Details
                  </div>
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                        >
                          <v-card
                            class="mx-auto my-12"
                          >
                            <v-img
                              height="250"
                              contain
                              src="/img/Parachute_Digital_Services_logo_high_res_2020_square.jpeg"
                            ></v-img>
                            <v-card-text class="text-center text-h3 mb-2 mt-2 blanketbutton--text">
                              Policy Holder: {{ prefix }} {{ firstName }} {{ lastName }}
                            </v-card-text>
                            <v-card-text class="text-center text-h3 mb-2 mt-2 blanketbutton--text">
                              Age: {{ calcage }}
                            </v-card-text>
                            <v-card-text class="text-center text-h3 mb-2 mt-2 blanketbutton--text">
                              Policy Face Amount: {{ faceAmount }}
                            </v-card-text>
                            <v-card-text class="text-center text-h3 mb-2 mt-2 blanketbutton--text">
                              Policy Term Length: {{ termLengths[termLength] }}
                            </v-card-text>

                            <v-card-text class="text-center text-h3 mb-2 mt-2 blanketbutton--text">
                              Status: Approved pending payment
                            </v-card-text>
                            <v-row class="d-flex justify-center ma-2 pa-2">
                              <v-btn
                                color="blanketbutton"
                                outlined
                                text
                              >
                                Pay Now
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blanketbutton"
                                outlined
                                text
                              >
                                Save Quote
                              </v-btn>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card
                  v-if="kickouts.length > 0"
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    We're Sorry
                  </div>
                  <v-card-text>
                    You are not eligible for this product due to the following reasons:
                    <ul>
                      <li
                        v-for="(kickout, index) in kickouts"
                        :key="index"
                      >
                        {{ kickout }}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
                <v-row class="pa-2 ma-2">
                  <v-btn
                    text
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="main"
                  >
                    Main
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <div class="mb-16">
    </div>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'QuicktermSurvey',
    // <----------------------------------------DATA---------------------------------------->
    data: () => ({
      suffix: '',
      suffixes: ['JR', 'SR', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'MD', 'DDS', 'DVD', 'ESQ', 'DO', 'DMD', 'PhD'],
      kickouts: [],
      mampros: '',
      bmonths: '',
      bdays: '',
      byears: '',
      schizophrenia: '',
      recentSymptoms: '',
      boneMarrow: '',
      awaitingResults: '',
      additionalIllness: '',
      prostateFollowup1: '',
      prostateFollowup2: '',
      prostateFollowup3: '',
      bladderFollowup1: '',
      bladderFollowup2: '',
      bladderFollowup3: '',
      kidneyDisease: '',
      kidneyDiseaseTypes: [],
      kidneyDiseaseList: ['Chronic kidney disease or polycystic kidney disease (PKD)', 'Glomerulonephritis', 'Kidney dialysis', 'Any disorder or disease affecting the bladder', 'Any disorder or disease affecting the prostate'],
      kidneyDiseaseKickouts: ['Chronic kidney disease or polycystic kidney disease (PKD)', 'Glomerulonephritis', 'Kidney dialysis'],
      stomachDisease: '',
      stomachDiseaseTypes: [],
      stomachDiseaseList: ['Disorder or disease of the pancreas', 'Chron’s disease', 'Ulcerative colitis', 'Another disorder or disease of the stomach, esophagus or bowel'],
      stomachDiseaseKickouts: ['Disorder or disease of the pancreas'],
      convulsions: '',
      bloodPressure: '',
      bloodPressureTypes: '',
      bloodPressureList: ['High blood pressure', 'High cholesterol', 'Blood clot or deep vein thrombosis or any other blood vessel disease or disorder', 'Any other circulatory disease or disorder'],
      bloodPressureKickouts: ['Blood clot or deep vein thrombosis or any other blood vessel disease or disorder', 'Any other circulatory disease or disorder'],
      hivAids: '',
      huntingtons: '',
      cysticFibrosis: '',
      dangerousOccupation: '',
      addiction: '',
      marijuanaWithTobacco: '',
      weeksInCountry: '',
      weeksArray: ['0 to 8 weeks', '9 to 12 weeks', '13 to 26 weeks', '27 to 52 weeks'],
      destination: '',
      destinations: ['Destination A', 'Destination B'],
      traveled: '',
      amountSliderMax: '',
      householdIncome: '',
      incomes: ['<$15K', '$15K-$25K', '$25K-$35K', '$35K-$50K', '$50K-$75K', '$75K-$100K', '$100K+'],
      faceAmount: '',
      faceAmounts: [],
      termLengths: ['10 Years', '15 Years', '20 Years'],
      termLength: '10 Years',
      faceAmountSlider: '',
      ptsdFollowup1: '',
      ptsdFollowup2: '',
      anxietyFollowup1: '',
      anxietyFollowup2: '',
      depressionFollowup1: '',
      depressionFollowup2: '',
      depressionFollowup3: '',
      colitisFollowup1: '',
      colitisFollowup2: '',
      colitisFollowup3: '',
      colitisFollowup4: '',
      colitisFollowup5: '',
      chronsFollowup1: '',
      chronsFollowup2: '',
      chronsFollowup3: '',
      chronsFollowup4: '',
      chronsFollowup5: '',
      sleepApnea: '',
      sleepApneaList: ['Less than 2 times per week', '2 or 3 times per week', '3 or more times per week'],
      sleepApneaKickouts: ['2 or 3 times per week', '3 or more times per week'],
      ashtmaFollowup1: '',
      ashtmaFollowup2: '',
      ashtmaFollowup3: '',
      arthritisFollowup1: '',
      arthritisFollowup2: '',
      arthritisFollowup3: '',
      bloodClotFollowup1: '',
      bloodClotFollowup2: '',
      heartDiseaseFollowup1: '',
      heartDiseaseFollowup2: '',
      heartDiseaseFollowup3: '',
      heartDiseaseFollowup4: '',
      diabetesFollowup: '',
      bloodPressureFollowup: '',
      cholesterolFollowup1: '',
      cholesterolFollowup2: '',
      covid: '',
      recentTravel: '',
      mentalHealthTypes: [],
      mentalHealthList: ['Depression', 'Anxiety', 'Post-traumatic stress disorder'],
      liverDiseaseTypes: [],
      liverDiseaseList: ['Hepatitis (other than hepatitis A)', 'Any other liver disorders', 'Chronic kidney disease', 'Chron’s disease', 'Ulcerative colitis', 'Any other disorder of the intestines'],
      liverDiseaseKickouts: ['Hepatitis (other than hepatitis A)', 'Any other liver disorders', 'Chronic kidney disease', 'Any other disorder of the intestines'],
      lungDiseaseTypes: [],
      lungDiseaseList: ['Chronic obstructive pulmonary disease (COPD)', 'Emphysema', 'Asthma', 'Sleep apnea', 'Any other respiratory system conditions'],
      lungDiseaseKickouts: ['Chronic obstructive pulmonary disease (COPD)', 'Emphysema', 'Any other respiratory system conditions'],
      autoimmuneDiseaseTypes: [],
      autoimmuneDiseaseList: ['Rheumatoid or psoriatic arthritis', 'Systemic lupus', 'Any other immune system disorders'],
      autoimmuneDiseaseKickouts: ['Systemic lupus', 'Any other immune system disorders'],
      heartDiseaseTypes: [],
      heartDiseaseList: ['Diabetes', 'Stroke or transient ischemic attack (TIA)', 'Heart disease', 'Heart attack', 'Peripheral artery disease'],
      heartDiseaseKickouts: ['Stroke or transient ischemic attack (TIA)', 'Heart attack', 'Peripheral artery disease'],
      mentalHealth: '',
      hiv: '',
      liverDisease: '',
      lungDisease: '',
      autoimmuneDisease: '',
      cognitiveDisease: '',
      cancerDiagnosis: '',
      heartDisease: '',
      gender: 'Male',
      maleRelativeHadCancer: '',
      femaleRelativeHadCancer: '',
      beenDiagnosed: '',
      alcoholFrequency: '',
      alcoholFrequencies: ['0-7', '8-14', '15-21', '22-28', 'More than 28'],
      marijuanaFrequency: '',
      marijuanaFrequencies: ['1-3', '4-6', '7-10', '11+'],
      usedMarijuana: '',
      doneRecreationalDrugs: '',
      prefixes: ['Mr.', 'Mrs.', 'Ms.', 'Miss'],
      prefix: '',
      firstName: '',
      lastName: '',
      middleInitial: '',
      age: '',
      disable: false,
      participateInNext2Years: '',
      participatedInPast2Years: '',
      dangerousHobbies: false,
      criminalHistory: false,
      beenDeclined: false,
      feet: '',
      inches: '',
      centimeters: '',
      kilograms: '',
      pounds: '',
      units: 'standard',
      citizen: false,
      replacePolicy: false,
      bmi: '',
      currentInsurance: false,
      coverageAmounts: ['Amount 1', 'Amount 2', 'Amount 3'],
      smoker: '',
      e1: 1,
    }),
    // <--------------------------------------COMPUTED PROPERTIES-------------------------------------->
    computed: {
      facevalue () {
        switch (this.faceAmount) {
          case '$50K':
            return 50000
          case '$75K':
            return 75000
          case '$100K':
            return 100000
          case '$150K':
            return 150000
          case '$200K':
            return 200000
          case '$250K':
            return 250000
          case '$300K':
            return 300000
          case '$350K':
            return 350000
          case '$400K':
            return 400000
          case '$450K':
            return 450000
          case '$500K':
            return 500000
          case '$550K':
            return 550000
          case '$600K':
            return 600000
          case '$650K':
            return 650000
          case '$700K':
            return 700000
          case '$750K':
            return 750000
          case '$800K':
            return 800000
          case '$850K':
            return 850000
          case '$900K':
            return 900000
          case '$950K':
            return 950000
          case '$1M':
            return 1000000
          default:
            return 0
        }
      },
      existingInsuranceValue () {
        switch (this.totalCoverageAmountOwned) {
          case '$50K':
            return 50000
          case '$75K':
            return 75000
          case '$100K':
            return 100000
          case '$150K':
            return 150000
          case '$200K':
            return 200000
          case '$250K':
            return 250000
          case '$300K':
            return 300000
          case '$350K':
            return 350000
          case '$400K':
            return 400000
          case '$450K':
            return 450000
          case '$500K':
            return 500000
          case '$550K':
            return 550000
          case '$600K':
            return 600000
          case '$650K':
            return 650000
          case '$700K':
            return 700000
          case '$750K':
            return 750000
          case '$800K':
            return 800000
          case '$850K':
            return 850000
          case '$900K':
            return 900000
          case '$950K':
            return 950000
          case '$1M':
            return 1000000
          default:
            return 0
        }
      },
      yearsavailable () {
        let maxyear = Number(moment().format('YYYY')) - 18
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i <= maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        console.log('our months ', ucMonths)
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      calcage () {
        let age = ''
        let monthnum = moment(this.bmonths, 'MM')
        console.log('num month ', monthnum)
        if (this.byears && this.bmonths && this.bdays) {
          let date = moment(this.byears + '-' + this.bmonths + '-' + this.bdays, 'YYYY-MMM-DD')
          age = moment().diff(date, 'years')
          if (age < 20 || age > 70) {
            this.addKickout('Ineligible due to age')
          }
        }
        if (isNaN(age)) {
          let dateparams = this.byears + '-' + this.bmonths + '-' + this.bdays
          return ''
        }
        return age
      },
      // faceAmount () {
      //   return this.faceAmounts[this.faceAmountSlider] || ''
      // },
      calcBMI () {
        if (this.units === 'standard') {
          if (this.inches !== '' && this.pounds !== '') {
            return Number(703 * ((this.pounds) / (this.inches * this.inches))).toFixed(1)
          }
        } else if (this.units === 'metric') {
          if (this.centimeters !== '' && this.kilograms !== '') {
            let meters = this.centimeters / 100
            return Number((this.kilograms) / (meters * meters)).toFixed(1)
          }
        } return ''
      }
    },
    // <---------------------------------METHODS--------------------------------->
    methods: {
      chooseAmount () {
        console.log('TOTALCOVERAGEAMOUNTOWNED: ', this.totalCoverageAmountOwned)
        console.log(this.facevalue)
        console.log(this.existingInsuranceValue)
        if (this.totalCoverageAmountOwned !== '') {
          if (this.existingInsuranceValue + this.facevalue > 1000000) {
            this.addKickout('The aggregate amount of Parachute Term Life insurance you are entitled to under all such policies combined shall not exceed $1,000,000.')
          }
        }
        this.amountDialog = false
      },
      addKickout (reason) {
        this.kickouts.push(reason)
        console.log(this.kickouts)
      },
      checkKidneyKickout () {
        let denied = this.kidneyDiseaseKickouts.some(i => this.kidneyDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history')
        }
      },
      checkStomachKickout () {
        let denied = this.stomachDiseaseKickouts.some(i => this.stomachDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history')
        }
      },
      checkBloodPressureKickout () {
        let denied = this.bloodPressureKickouts.some(i => this.bloodPressureTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history')
        }
      },
      travelTimeKickout () {
        if (this.weeksInCountry === '13 to 26 weeks' || this.weeksInCountry === '27 to 52 weeks') {
          this.addKickout('Ineligible due to travel.')
        }
      },
      destinationKickout () {
        if (this.destination === 'Destination B') {
          this.addKickout('Ineligible due to travel.')
        }
      },
      incomeEligibility () {
        console.log(this.householdIncome)
        switch (this.householdIncome) {
          case '<$15K': this.faceAmounts = ['$50K', '$75K', '$100K']; this.kickouts.push('Ineligible due to income'); break
          case '$15K-$25K': this.faceAmounts = ['50K', '75K', '100K']; break
          case '$25K-$35K': this.faceAmounts = ['50K', '75K', '100K', '150K', '200K', '250K']; break
          case '$35K-$50K': this.faceAmounts = ['50K', '75K', '100K', '150K', '200K', '250K', '300K', '350K', '400K', '450K', '500K']; break
          case '$50K-$75K': this.faceAmounts = ['50K', '75K', '100K', '150K', '200K', '250K', '300K', '350K', '400K', '450K', '500K', '550K', '600K', '650K', '700K', '750K']; break
          case '$75K-$100K': this.faceAmounts = ['50K', '75K', '100K', '150K', '200K', '250K', '300K', '350K', '400K', '450K', '500K', '550K', '600K', '650K', '700K', '750K', '800K', '850K', '900K', '950K', '1M']; break
          case '$100K+': this.faceAmounts = ['50K', '75K', '100K', '150K', '200K', '250K', '300K', '350K', '400K', '450K', '500K', '550K', '600K', '650K', '700K', '750K', '800K', '850K', '900K', '950K', '1M']; break
        }
      },
      checkSleepApneaKickouts () {
        let denied = this.sleepApneaKickouts.some(i => this.sleepApnea.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history')
        }
      },
      checkMentalKickouts () {
        let denied = this.mentalHealthKickouts.some(i => this.mentalHealthTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history.')
        }
      },
      checkLiverKickouts () {
        let denied = this.liverDiseaseKickouts.some(i => this.liverDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history.')
        }
      },
      checkLungKickouts () {
        let denied = this.lungDiseaseKickouts.some(i => this.lungDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history.')
        }
        if (this.lungDiseaseTypes.includes('Sleep apnea') && this.bmi > 32) {
          this.addKickout('Denied due to medical history.')
        }
      },
      checkAutoimmuneKickouts () {
        let denied = this.autoimmuneDiseaseKickouts.some(i => this.autoimmuneDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history.')
        }
      },
      checkHeartKickouts () {
        let denied = this.heartDiseaseKickouts.some(i => this.heartDiseaseTypes.includes(i))
        if (denied) {
          this.addKickout('Denied due to medical history.')
        }
        if (this.heartDiseaseTypes.includes('Diabetes') && this.gender === 'Male') {
          this.addKickout('Denied due to medical history.')
        }
      },
      alcoholKickout () {
        if (this.alcoholFrequency === '22-28' || this.alcoholFrequency === 'More than 28') {
          this.addKickout('Denied due to alcohol use.')
        }
      },
      marijuanaKickout () {
        if (this.marijuanaFrequency === '7-10' || this.marijuanaFrequency === '11+') {
          this.addKickout('Denied due to Marijuana frequency of use')
        }
      },
      hobbyKickout () {
        if (this.participatedInPast2Years === 1 && this.participateInNext2Years !== 0) {
          this.addKickout('Declined due to participation in hazardous activities.')
        }
      },
      checkBMI () {
        if (this.calcBMI < 17.5 || this.calcBMI > 32.0) {
          console.log(this.calcBMI)

          this.addKickout('Ineligible due to BMI')
        }
      },
      checkCentimeters () {
        if (this.centimeters < 135 || this.centimeters > 200) {
          this.addKickout('Ineligible due to height of ' + this.centimeters)
        }
      },
      checkKilograms () {
        if (this.kilograms < 37.5 || this.kilograms > 142) {
          this.addKickout('Ineligible due to wweight of ' + this.kilograms)
        }
      },
      checkInches () {
        if (this.inches < 53 || this.inches > 78.7) {
          this.addKickout('Ineligible due to height of ' + this.inches)
        }
      },
      checkPounds () {
        if (this.pounds < 82.6 || this.pounds > 313) {
          this.addKickout('Ineligible due to pounds of ' + this.pounds)
        }
      },
      getMailingAddressData (addressData, placeResultData, id) {
        this.docmailingAddress = addressData.name
        this.docmailingCity = addressData.locality
        this.docmailingZip = addressData.postal_code
        this.docmailingState = addressData.administrative_area_level_1
      },
      getResidentialAddressData (addressData, placeResultData, id) {
        this.currentuser.address = addressData.name
        this.currentuser.city = addressData.locality
        this.currentuser.postcode = addressData.postal_code
        this.currentuser.state = addressData.administrative_area_level_1
      },
      nextquestion (completedQuestion) {
        this.e1++
        // this.$gtag.event('Symetra Term Life Applicaton ', { question: completedQuestion, campaign: 'none' })
        console.log('step completed ', completedQuestion)
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      main () {
        this.$router.push('/')
      },
      cancel () {
        this.e1--
        if (this.e1 < 1) {
          this.e1 = 1
        }
      }
    }
  }
</script>

<style scoped>
.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
</style>
